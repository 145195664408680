import qs from "qs";

export function printNumbersWithSpaceAsThousandsSeparator(number) {
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function getEffectif(classNumber) {
  const effectifs = {
    1: "Classe 1 (de 1 à 3 exécutants)",

    2: "Classe 2 (de 4 à 8 exécutants)",

    3: "Classe 3 (de 9 à 19 exécutants)",

    4: "Classe 4 (de 20 à 49 exécutants)",

    5: "Classe 5 (de 50 à 250 exécutants)",

    6: "Classe 6 (supérieur à 250 exécutants)",
  };

  return effectifs[classNumber] ? effectifs[classNumber] : classNumber;
}
